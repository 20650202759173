import React from 'react';

export const Vimeo = ({ title, id }) => (
    <div className="video-wrapper">
        <iframe
            src={`https://player.vimeo.com/video/${id}?h=cd29a7c13c`}
            title={title}
            frameborder={0}
            allow="autoplay; fullscreen; picture-in-picture"
            allowfullscreen={true}
        />
    </div>
);
