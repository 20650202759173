import React from 'react';
import { graphql } from 'gatsby';
import { OutboundLink } from 'gatsby-plugin-google-analytics';

import {
    IntroHolder,
    TwoColumns,
    Sidebar,
    Reference,
    Content,
    IntroQuote,
    CenteredQuote,
} from '@bahaide/shared/components/InnerPage';
import { Vimeo } from '@bahaide/shared/components/Vimeo';

import InnerPage from '../../../../components/InnerPage';

const AbdulBaha18441921 = ({ data }) => (
    <InnerPage title="‘Abdu’l-Bahá (1844-1921)" description="">
        <IntroHolder
            title="‘Abdu’l-Bahá (1844-1921)"
            subtitle="‘Abdu’l-Bahá – das vollkommene Vorbild"
            picture={data.introImage}
            breadcrumbs={{
                grandparent: 'Woran Bahá’í glauben',
                parent: 'Bahá’u’lláh und Sein Anspruch',
                'current-item': '‘Abdu’l-Bahá (1844-1921)',
            }}
        >
            <IntroQuote>
                Jeder, der mit ihm verkehrte, fand in ihm einen außergewöhnlich
                wohlunterrichteten Mann, dessen einnehmende Rede sowohl den
                Verstand als auch die Seele anspricht und der dem Glauben an die
                Einheit der Menschheit gänzlich ergeben ist.
            </IntroQuote>
        </IntroHolder>

        <TwoColumns>
            <div style={{ marginBottom: 40 }}>
                <Vimeo title="EXEMPLAR - Das Vorbild" id="665110972" />
            </div>

            <Content>
                <p>
                    ‘Abdu’l-Bahá war der älteste Sohn Bahá’u’lláhs, des Stifters
                    der Bahá’í-Religion. Nach Dessen Hinscheiden 1892 wurde
                    ‘Abdu’l-Bahá berufen, die Angelegenheiten der
                    Bahá’í-Gemeinde weiter zu führen. Er war bekannt und
                    angesehen als Verfechter für soziale Gerechtigkeit und
                    Botschafter für den internationalen Frieden.
                </p>
                <div>
                    <CenteredQuote>
                        Ich heiße euch alle und jeden von euch, alles, was ihr
                        im Herzen habt, auf Liebe und Einigkeit zu richten. Wenn
                        ein Kriegsgedanke kommt, so widersteht ihm mit einem
                        stärkeren Gedanken des Friedens. Ein Hassgedanke muss
                        durch einen mächtigeren Gedanken der Liebe vernichtet
                        werden.
                    </CenteredQuote>
                    <h2>Ein Leben im Dienst an der Menschheit</h2>
                    <p>
                        ‘Abdu’l-Bahá („Diener der Herrlichkeit Gottes“) wurde am
                        23. Mai 1844 in Teheran, im heutigen Iran, geboren. Von
                        frühester Kindheit an erlebte Er die religiöse
                        Verfolgung der Anhänger des Báb und auch die mehrfache
                        Verbannung seiner Familie.
                    </p>
                    <p>
                        ‘Abdu’l-Bahá wuchs zu einer unersetzlichen Stütze Seines
                        Vaters heran. Häufig trat Er als Repräsentant
                        Bahá’u’lláhs auf, wenn es um Kontakte zu offiziellen
                        Stellen oder Persönlichkeiten des öffentlichen Lebens
                        ging. Nach zeitgenössischen Berichten beeindruckte Seine
                        Persönlichkeit jeden, der Ihm begegnete. Die Einwohner
                        ‘Akkás staunten über Seine tatkräftige
                        Einsatzbereitschaft für Arme und Kranke und priesen
                        Seine außergewöhnliche Hilfsbereitschaft.
                    </p>
                    <p>
                        Als Bahá’u‘lláh 1892 starb, war ‘Abdu’l-Bahá immer noch
                        ein Gefangener des osmanischen Reiches. Das hinderte Ihn
                        jedoch nicht daran, durch umfangreiche Briefwechsel die
                        Geschicke der weltweit wachsenden Bahá‘í-Gemeinde zu
                        lenken und Gäste wie Pilger aus Ost und West zu
                        empfangen.
                    </p>
                    <p>
                        Im Jahre 1908, nach der Jungtürkischen Revolution,
                        erlangte Er im Alter von 64 Jahren die Freiheit. Diese
                        Freiheit nutzte ‘Abdu’l-Bahá, um ab 1910 die noch jungen
                        Bahá’í-Gemeinden in Ägypten, Nordamerika und Europa zu
                        besuchen. Er traf mit Menschen aller gesellschaftlichen
                        Schichten zusammen: mit bedeutenden Persönlichkeiten aus
                        Wirtschaft und Politik, mit Klerikern, Wissenschaftlern,
                        Künstlern, Schriftstellern und Journalisten – aber auch
                        mit Obdachlosen und Bettlern.
                    </p>
                    <p>
                        Viele der dort gehaltenen öffentlichen Ansprachen wurden
                        aufgezeichnet und in Buchform veröffentlicht, darunter
                        die sogenannten Ansprachen in Paris. Dort erläutert
                        ‘Abdu’l-Bahá unter anderem die wichtigsten Grundsätze
                        der Bahá’í-Religion, darunter den Abbau von Vorurteilen
                        jeglicher Art, die Gleichberechtigung von Mann und Frau,
                        die Vereinbarkeit von Wissenschaft und Religion und die
                        eigenständige Suche nach Wahrheit statt dem blinden
                        Folgen von Traditionen.
                    </p>
                    <p>
                        Immer wieder betonte ‘Abdu’l-Bahá, wie wichtig der
                        Ausgleich zwischen Reichtum und Armut sei sowie die
                        Integration von Minderheiten und die universelle
                        Erziehung und Bildung für Jungen und Mädchen. Dadurch
                        würden alle Menschen zur selbständigen Erforschung der
                        Wirklichkeit befähigt werden – unbehindert von Fesseln
                        alter Dogmen.
                    </p>
                    <p>
                        1920 wurde Er in Anerkennung der von Ihm geleisteten
                        humanitären Hilfe in den Kriegsjahren zum Ritter des
                        Britischen Empire geschlagen. Im Folgejahr verstarb Er
                        nach einem langen, aufopferungsvollen Leben in Haifa.
                        Seiner Beisetzung wohnten auch hohe Staatsbeamte und
                        Oberhäupter der verschiedenen religiösen Gemeinschaften
                        bei. Etwa 10.000 Menschen aus allen Schichten der
                        Gesellschaft trauerten um den Verlust des „Meisters“ –
                        wie sie ‘Abdu’l-Bahá respektvoll nannten.
                    </p>
                    <p>
                        <img
                            alt=""
                            src={
                                data.abdulbahaCollage.childImageSharp.fluid.src
                            }
                            className="aligncenter"
                        />
                    </p>
                    <h2>Die Stufe 'Abdu’l-Bahás im Bahá’í-Glauben</h2>
                    <p>
                        Bahá’u’lláh setzte ‘Abdu’l-Bahá testamentarisch als
                        Oberhaupt des Glaubens ein und verlieh Ihm weiterhin die
                        Autorität, die Heiligen Schriften verbindlich
                        auszulegen. Mit dieser religionsgeschichtlich
                        einzigartigen Maßnahme schützte Bahá’u’lláh den jungen
                        Glauben von Anbeginn vor Spaltung und Sektenbildung und
                        bewahrte seine Einheit – ein Grundprinzip der
                        Bahá’í-Religion. Zudem rief Bahá’u’lláh alle Gläubigen
                        dazu auf, nach Seinem Tod ‘Abdu’l-Bahá als vollkommenem
                        Vorbild zu folgen und in Ihm ein vollkommenes Beispiel
                        für hingebungsvolle Nächstenliebe und unermüdlichen
                        Dienst für die Menschheit zu sehen.
                    </p>
                    <p>
                        ‘Abdu’l-Bahás außergewöhnliche Charaktereigenschaften,
                        Sein Wissen und Sein Einsatz für alle Menschen boten ein
                        anschauliches Beispiel der praktischen Umsetzung von
                        Bahá’u’lláhs Lehren und verhalfen der weltweit
                        wachsenden Gemeinde zu Ansehen. Er förderte die
                        Einrichtung der von Bahá’u’lláh vorgesehenen örtlichen
                        Bahá’í-Institutionen und betreute die entstehenden
                        erzieherischen, sozialen und wirtschaftlichen
                        Initiativen.
                    </p>
                    <p>
                        ‘Abdu’l-Bahá weilte vom 1. bis 8. April 1913 auch in
                        Deutschland (siehe{' '}
                        <OutboundLink href="http://www.abdulbaha-in-deutschland.de/">
                            ‘Abdu’l-Bahá in Deutschland
                        </OutboundLink>
                        ), besuchte dann Budapest und Wien, wo Er unter anderen
                        die spätere Friedensnobelpreisträgerin Bertha von
                        Suttner empfing. Am 26. April kehrte Er nochmals nach
                        Stuttgart zurück und reiste am 1. Mai 1913 weiter nach
                        Paris.
                    </p>
                    <p>
                        Der erste Höhepunkt der Geschichte des Bahá'í-Glaubens
                        in Deutschland ist zweifellos der Besuch ‘Abdu’l-Bahás.
                        Am 1. April 1913 traf ‘Abdu’l-Bahá in Stuttgart ein. Ein
                        neues Kapitel in der jungen Geschichte der
                        Bahá'í-Gemeinde in Deutschland hatte begonnen. Der
                        älteste Sohn Bahá'u'lláhs, des Stifters der
                        Bahá'í-Religion, war in die Stadt gekommen, in der die
                        Lehren Bahá'u'lláhs auf deutschem Boden im Jahr 1905
                        zuerst Wurzeln geschlagen hatte. ‘Abdu’l-Bahá verweilte
                        insgesamt 14 Tage in Deutschland und besuchte auch Bad
                        Mergentheim und Esslingen. Für die Bahá'í in Deutschland
                        war dieser Besuch ein überwältigendes Erlebnis, welches
                        die Geschickte der jungen Gemeinde nachhaltig
                        beeinflusste. In öffentlichen Vorträgen rief er zu einem
                        friedlichen Miteinander der Völker und zur religiösen
                        Verständigung auf und setzte anhaltende Impulse für die
                        weitere Entwicklung der Gemeinde.
                    </p>
                    <p>
                        ‘Abdu’l-Bahás Vision bedeutete nichts weniger als eine
                        grundlegende Veränderung menschlicher Gesellschaft und
                        ihrer Strukturen. Seine Botschaft des Friedens und der
                        Aussöhnung zwischen den Religionen und Kulturen besitzt
                        heute unveränderte Aktualität.
                    </p>
                    <h2>Wegbereiter für ein neues Zeitalter</h2>
                    <p>
                        Zu seiner Beisetzung kamen 10.000 Menschen jeglichen
                        Hintergrunds in Haifa zusammen und die Gedenkworte von
                        christlichen, muslimischen und jüdischen Vertretern
                        betonten, ‘Abdu’l-Bahás tatkräftiges Wirken für den
                        gesellschaftlichen Zusammenhalt. Mit dem Hinscheiden
                        ‘Abdu’l-Bahá 1921 endete für die Bahá’í das sogenannte
                        „Heroische Zeitalter“, das 1844 mit der Erklärung des
                        Báb begonnen hatte. Dieser Zeitraum war gekennzeichnet
                        durch das Auftreten der drei Zentralgestalten des
                        Bahá’í-Glaubens – die beiden Gottesoffenbarer Báb und
                        Bahá’u’lláh sowie ‘Abdu’l-Bahá. In dieser frühen Zeit
                        gaben tausende Gläubige im damaligen Persien unter
                        heftiger Verfolgung ihr Leben für den jungen Glauben
                        hin.
                    </p>
                    <p>
                        In Seinem Testament ernannte ‘Abdu’l-Bahá Seinen
                        ältesten Enkel Shoghi Effendi zu Seinem Nachfolger als
                        ‚Hüter‘ des Glaubens.
                    </p>
                </div>
                <Reference>
                    <p>1. Al-Mu’ayyad Tageszeitung, Ägypten 16. Oktober 1910</p>
                </Reference>
            </Content>
            <Sidebar />
        </TwoColumns>
    </InnerPage>
);

export default AbdulBaha18441921;

export const pageQuery = graphql`
    query {
        introImage: file(relativePath: { eq: "abdul-baha-feature.jpg" }) {
            ...fluidImage
        }
        abdulbahaCollage: file(relativePath: { eq: "abdulbaha-collage.png" }) {
            ...fluidImage
        }
    }
`;
